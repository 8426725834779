<template>
  <div class="dashboard-nav mt-2">
    <b-nav class="border-bottom p-0">
      <b-nav-item :to="{ name: 'BuyerDashboard' }" exact exact-active-class="active">{{
        $t("dashboard.label.mnuDashboard")
      }}</b-nav-item>
      <b-nav-item :to="{ name: 'MyWatchList' }" exact exact-active-class="active">{{
        $t("dashboard.label.mnuWatchlist")
      }}</b-nav-item>
      <b-nav-item :to="{ name: 'MyReservations' }" exact exact-active-class="active">{{
        $t("dashboard.label.mnuReservations")
      }}</b-nav-item>
      <b-nav-item :to="{ name: 'MyPurchased' }" exact exact-active-class="active">{{
        $t("dashboard.label.mnuPurchased")
      }}</b-nav-item>
      <b-nav-item
        :to="{ name: 'PrimaryContact' }"
        exact
        :active="$route.fullPath.indexOf('profile') !== -1"
        >{{ $t("dashboard.label.mnuMyAccount") }}</b-nav-item
      >
    </b-nav>
  </div>
</template>
<script>
export default {
  components: {},
  data() {
    return {
      wishList: null,
      reservations: null,
      order: null,
    };
  },
  props: ["sideBar"],
  methods: {
    hasActiveChildren(path) {
      return this.$route.path.indexOf(path) !== -1;
    },
  },
};
</script>

<style scoped lang="scss">


.nav-link {
  color: #363636;
  margin-right: 16px;
}
.nav-link:hover {
  color: $primary !important;
}
.active {
  border-bottom: 3px solid $primary !important;
  margin-bottom: -1px;
  font-weight: 500;
  color: #111;
}
.dashboard-nav {
  margin-top: 24px;
}
</style>
