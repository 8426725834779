<template>
  <b-list-group v-show="!sideBar">
    <router-link :to="{ name: 'BuyerDashboard' }" class="routerLink">
      <b-list-group-item
        button
        class="d-flex align-items-center border-0 enlarge rounded"
        v-bind:class="{
          'px-0 ': sideBar,
          active: hasActiveChildren('buyerdashboard')
        }"
      >
        <span class="mr-auto" v-show="!sideBar">{{
          $t("dashboard.label.mnuDashboard")
        }}</span>
      </b-list-group-item></router-link
    >
    <!-- Hide for now -->
    <!-- <router-link :to="{ name: 'Notifications' }" class="routerLink">
      <b-list-group-item
        button
        class="d-flex align-items-center border-0 enlarge"
        v-bind:class="{
          'px-0 ': sideBar,
          active: hasActiveChildren('notifications')
        }"
      >
        <span class="mr-auto" v-show="!sideBar">{{
          $t("dashboard.label.mnuNotifications")
        }}</span>
        <b-badge class="bg-warning button-rounded" v-show="sideBar">0</b-badge>
      </b-list-group-item></router-link
    > -->
    <!-- Hide for now -->

    <router-link :to="{ name: 'MyWatchList' }" class="routerLink">
      <b-list-group-item
        button
        class="d-flex align-items-center border-0 enlarge rounded"
        v-bind:class="{
          'px-0 ': sideBar,
          active: hasActiveChildren('mywatchlist')
        }"
      >
        <span class="mr-auto" v-show="!sideBar">{{
          $t("dashboard.label.mnuWatchlist")
        }}</span>
        <b-badge
          class="bg-warning button-rounded"
          v-show="$store.getters.buyerMenuNumber"
          >{{ $store.getters.buyerMenuNumber }}</b-badge
        >
      </b-list-group-item></router-link
    >
    <router-link :to="{ name: 'MyReservations' }" class="routerLink">
      <b-list-group-item
        button
        class="d-flex align-items-center border-0 enlarge rounded"
        v-bind:class="{
          'px-0 ': sideBar,
          active: hasActiveChildren('myreservations')
        }"
      >
        <span class="mr-auto" v-show="!sideBar">{{
          $t("dashboard.label.mnuReservations")
        }}</span>
        <b-badge class="bg-warning button-rounded" v-show="sideBar">0</b-badge>
      </b-list-group-item></router-link
    >
    <!-- Hide for now -->
    <!-- <router-link :to="{ name: 'MyOrder' }" class="routerLink">
      <b-list-group-item
        button
        class="d-flex align-items-center border-0 enlarge"
        v-bind:class="{
          'px-0 ': sideBar,
          active: hasActiveChildren('myorder')
        }"
      >
        <span class="mr-auto" v-show="!sideBar">{{
          $t("dashboard.label.mnuOrders")
        }}</span>
        <b-badge class="bg-warning button-rounded" v-show="sideBar">0</b-badge>
      </b-list-group-item>
    </router-link> -->
    <!-- Hide for now -->

    <!-- <router-link :to="{ name: 'TransactionHistory' }" class="routerLink">
      <b-list-group-item
        button
        class="d-flex align-items-center border-0 enlarge"
        v-bind:class="{
          'px-0 ': sideBar,
          active: hasActiveChildren('transactionhistory')
        }"
      >
        <span class="mr-auto" v-show="!sideBar">Transaction History</span>
      </b-list-group-item>
    </router-link> -->

    <router-link :to="{ name: 'PrimaryContact' }" class="routerLink">
      <b-list-group-item
        button
        class="d-flex align-items-center border-0 enlarge rounded"
        v-bind:class="{
          'px-0 ': sideBar,
          active: hasActiveChildren('profile')
        }"
      >
        <span class="mr-auto" v-show="!sideBar">{{
          $t("dashboard.label.mnuMyAccount")
        }}</span>
      </b-list-group-item>
    </router-link>
    <b-list-group-item
      class="
        d-flex
        justify-content-around
        border-bottom-0 border-right-0 border-left-0
        enlarge
      "
    >
      <!-- <TheSellButton /> -->
    </b-list-group-item>
  </b-list-group>
</template>
<script>
// import TheSellButton from "@/components/dashboard/global/TheSellAVehicleButton.vue";
export default {
  components: {
    // TheSellButton
  },
  data() {
    return {
      wishList: null,
      reservations: null,
      order: null
    };
  },
  props: ["sideBar"],
  methods: {
    hasActiveChildren(path) {
      return this.$route.path.indexOf(path) !== -1;
    }
  }
};
</script>
<style scoped>
.list-group-item {
  border-left: 5px solid rgba(34, 119, 239, 0) !important;
}

.list-group-item.active {
  border-left: 5px solid rgba(34, 119, 239, 1) !important;
  background-color: rgba(34, 119, 239, 0.1) !important;
  color: rgba(34, 119, 239, 1);
  font-weight: bold;
}
</style>
