<template>
  <div class="pt-2">
    <b-nav class="border-bottom p-0">
      <b-nav-item
        :to="{ name: 'SellerDashboard' }"
        exact
        exact-active-class="active"
        @click="nav = null"
        >{{ $t("dashboard.label.mnuDashboard") }}</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'MyListings' }"
        exact
        exact-active-class="active"
        @click="nav = null"
        >{{ $t("dashboard.label.mnuListings") }}</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'PendingSales' }"
        exact
        exact-active-class="active"
        @click="nav = null"
        >{{ $t("dashboard.label.mnuPending") }}</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'ConfirmedSales' }"
        exact
        exact-active-class="active"
        @click="nav = null"
        >{{ $t("dashboard.label.mnuConfirmed") }}</b-nav-item
      >
      <b-nav-item
        v-if="nav"
        :to="{ name: nav.name }"
        exact
        exact-active-class="active"
        >{{ nav.label }}</b-nav-item
      >
      <!-- <b-nav-item
        :to="{ name: 'PrimaryContact' }"
        exact
        :class="{ active: hasActiveChildren('profile') }"
        >{{ $t("dashboard.label.mnuMyAccount") }}</b-nav-item
      >
      <b-nav-item
        :to="{ name: 'GettingPaid' }"
        exact
        :class="{ active: hasActiveChildren('payment') }"
        >{{ $t("dashboard.label.mnuMyAccount") }} Payment</b-nav-item
      > -->
      <b-nav-item-dropdown right>
        <template #button-content>
          <b-icon-three-dots-vertical />
        </template>
        <b-dropdown-item
          :to="{ name: 'Archived' }"
          exact
          exact-active-class="active"
          @click="showNav('Archived', $t('dashboard.label.mnuArchived'))"
          >{{ $t("dashboard.label.mnuArchived") }}</b-dropdown-item
        >
        <b-dropdown-item
          :to="{ name: 'PrimaryContact' }"
          exact
          exact-active-class="active"
          @click="showNav('PrimaryContact', $t('dashboard.label.mnuMyAccount'))"
          >{{ $t("dashboard.label.mnuMyAccount") }}</b-dropdown-item
        >
        <b-dropdown-item
          :to="{ name: 'GettingPaid' }"
          exact
          exact-active-class="active"
          @click="showNav('GettingPaid', $t('dashboard.label.mnuPayment'))"
          >{{ $t("dashboard.label.mnuPayment") }}</b-dropdown-item
        >
      </b-nav-item-dropdown>
    </b-nav>
  </div>
</template>

<script>
export default {
  components: {},
  data() {
    return {
      nav: null
    };
  },
  props: ["sideBar"],
  methods: {
    hasActiveChildren(path) {
      return this.$route.path.indexOf(path) !== -1;
    },
    showNav(name, label) {
      this.nav = { name: name, label: label };
    }
  }
};
</script>
<style scoped lang="scss">


.nav-link {
  color: black;
}
.nav-link:hover {
  color: $primary !important;
}
.active {
  border-bottom: 3px solid $primary !important;
  margin-bottom: -1px;
}
</style>
