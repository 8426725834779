var render = function render(){var _vm=this,_c=_vm._self._c;return _c('b-list-group',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sideBar),expression:"!sideBar"}]},[_c('router-link',{staticClass:"routerLink",attrs:{"to":{ name: 'BuyerDashboard' }}},[_c('b-list-group-item',{staticClass:"d-flex align-items-center border-0 enlarge rounded",class:{
        'px-0 ': _vm.sideBar,
        active: _vm.hasActiveChildren('buyerdashboard')
      },attrs:{"button":""}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sideBar),expression:"!sideBar"}],staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("dashboard.label.mnuDashboard")))])])],1),_c('router-link',{staticClass:"routerLink",attrs:{"to":{ name: 'MyWatchList' }}},[_c('b-list-group-item',{staticClass:"d-flex align-items-center border-0 enlarge rounded",class:{
        'px-0 ': _vm.sideBar,
        active: _vm.hasActiveChildren('mywatchlist')
      },attrs:{"button":""}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sideBar),expression:"!sideBar"}],staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("dashboard.label.mnuWatchlist")))]),_c('b-badge',{directives:[{name:"show",rawName:"v-show",value:(_vm.$store.getters.buyerMenuNumber),expression:"$store.getters.buyerMenuNumber"}],staticClass:"bg-warning button-rounded"},[_vm._v(_vm._s(_vm.$store.getters.buyerMenuNumber))])],1)],1),_c('router-link',{staticClass:"routerLink",attrs:{"to":{ name: 'MyReservations' }}},[_c('b-list-group-item',{staticClass:"d-flex align-items-center border-0 enlarge rounded",class:{
        'px-0 ': _vm.sideBar,
        active: _vm.hasActiveChildren('myreservations')
      },attrs:{"button":""}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sideBar),expression:"!sideBar"}],staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("dashboard.label.mnuReservations")))]),_c('b-badge',{directives:[{name:"show",rawName:"v-show",value:(_vm.sideBar),expression:"sideBar"}],staticClass:"bg-warning button-rounded"},[_vm._v("0")])],1)],1),_c('router-link',{staticClass:"routerLink",attrs:{"to":{ name: 'PrimaryContact' }}},[_c('b-list-group-item',{staticClass:"d-flex align-items-center border-0 enlarge rounded",class:{
        'px-0 ': _vm.sideBar,
        active: _vm.hasActiveChildren('profile')
      },attrs:{"button":""}},[_c('span',{directives:[{name:"show",rawName:"v-show",value:(!_vm.sideBar),expression:"!sideBar"}],staticClass:"mr-auto"},[_vm._v(_vm._s(_vm.$t("dashboard.label.mnuMyAccount")))])])],1),_c('b-list-group-item',{staticClass:"d-flex justify-content-around border-bottom-0 border-right-0 border-left-0 enlarge"})],1)
}
var staticRenderFns = []

export { render, staticRenderFns }